<template>
    <div>
        <Header :activeIndex="1"></Header>
        <div :style="{minHeight: (minHeight - 250) + 'px'}">
            <div class="banner">
                <img class="bigImg" src="@/assets/images/officialWebsite/news/news-banner.png" alt="天游">
                <img class="smaImg" src="@/assets/images/officialWebsite/news/news-mbanner.png" alt="天游">
            </div>
            <ul class="newsNav">
                <li v-for="(item) in navList" :class="{active: item.category_id == activeIndex}" :key="item.category_id" @click="setTab(item.category_id)">{{item.category}}</li>
            </ul>
            <template v-if="newsList.length>0">
                <div class="newsInfo_con" v-for="(item) in newsList" :key="item.news_id" @click="newsDetail(item.news_id)">
                    <News :recruitDate="item"></News>
                </div>
                <div class="newsInfo_page">
                    <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page.sync="currentPage"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="pageSum"
                            :prev-text="changePage.pre"
                            :next-text="changePage.nex">
                    </el-pagination>
                </div>
            </template>
            <template v-else>
                <div class="newsInfo_noCon">
                    <img src="@/assets/images/officialWebsite/news/news-nocontent.png" alt="">
                </div>
            </template>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    export default {
        name: "NewsInfo",
        data (){
            return {
                navList: [],// 导航栏数组
                activeIndex: 1, // 当前导航栏索引
                newsList: [], // 新闻列表
                currentPage: 1, // 当前页
                pageSum: 0, // 新闻总数
                pageSize: 0, // 每页显示个数
                minHeight: 0,
                changePage:{
                    pre:"上一页",
                    nex:"下一页"
                }
            }
        },
        created() {
            this.getNewsList({ category_id:0,page:1})
        },
        mounted() {
            this.minHeight = document.documentElement.clientHeight;
            window.onresize = () => {
                this.minHeight = document.documentElement.clientHeight;
            }
        },
        methods: {
            newsDetail(id) {
                this.$router.push({path: '/newsDetail/'+ id})
            },
            setTab(id) {
                this.activeIndex = id
                this.getNewsList({ category_id: id, page: 1})
            },
            handleCurrentChange(val) {
                this.getNewsList({ category_id: this.activeIndex, page: val})
            },
            getNewsList (obj) {
                this.$API.getNewsList(obj).then((res)=>{
                    if (res.data.status == 1) {
                        if (res.data.data.categories.length != 0) {
                            this.navList = res.data.data.categories
                        }
                        this.newsList = res.data.data.category_infos
                        this.pageSum = res.data.data.count
                        this.pageSize = res.data.data.limit
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    @media screen and (min-width: 1200px) {
        .banner {
            .bigImg {
                width: 1200px;
                height: 480px;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
            }
        }
        .newsNav {
            height: 150px;
            justify-content: center;
            align-items: center;
            li {
                font-size: 24px;
                margin-right: 64px;
            }
        }
        .newsInfo_con {
            width: 1200px;
            margin: 0 auto;
            padding-bottom: 56px;
        }
        .newsInfo_page {
            width: 1120px;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 1200px) and (min-width: 600px) {
        .banner {
            .bigImg {
                width: 100%;
            }
        }
        .newsNav {
            height: 100px;
            justify-content: center;
            align-items: center;
            li {
                font-size: 24px;
                margin-right: 64px;
            }
        }
        .newsInfo_con {
            width: 92%;
            margin: 0 auto;
            padding-bottom: 36px;
        }
        .newsInfo_page {
            width: 92%;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 600px) {
        .banner {
            height: 260px !important;
            .bigImg {
                display: none;
            }
            .smaImg {
                display: block!important;
                width: 100%;
                height: 260px;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
            }
        }
        .newsNav {
            height: 75px;
            justify-content: center;
            align-items: center;
            li {
                font-size: 16px;
                font-weight: 600!important;
                margin-right: 30px;
            }
        }
        .newsInfo_con {
            width: 92%;
            margin: 0 auto;
            padding-bottom: 25px;
        }
        .newsInfo_page {
            width: 92%;
            margin: 0 auto;
        }
    }
    ul,li,div {margin: 0;padding: 0}
    .banner {
        width: 100%;
        background: #2F333D;
        height: 480px;
        position: relative;
        .bigImg {
            height: 480px;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
        }
        .smaImg {
            display: none;
        }
    }
    .newsNav {
        width: 100%;
        display: flex;
        li {
            font-weight: 500;
            color: #2F333D;
            cursor: pointer;
        }
        li:last-of-type {
            margin-right: 0;
        }
        .active {
            color: #D5A561;
            border-bottom: 2px solid #D5A561;
        }
    }
    .newsInfo_page {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 190px;
        margin-top: 40px;
        /deep/ button, li {
            background: none;
        }
        /deep/ li, .active {
            background: none;
            color: #D5A561!important;
        }
        /deep/ .el-input__inner {
            background: none;
        }
        /deep/ .el-input__inner:focus{border-color: #D5A561!important;}
    }
    .newsInfo_noCon {
        width: 100%;
        height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
        img {
            width: 270px;
            height: 200px;
        }
    }

</style>
